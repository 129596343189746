.faucet-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 130px;
  color: #fff;
}

.faucet-form--badge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  border: 0.5px solid rgba(255, 255, 255, .5);
  border-radius: 100px;
  background: rgba(255, 255, 255, .1);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.faucet-form--heading {
  font-size: 42px;
  font-weight: 700;
  line-height: 54px;
  text-align: center;
}

.faucet-form--content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 470px;
}

.faucet-form--label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
}

.faucet-form--submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 50px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  cursor: pointer;
  outline: none;
}

.faucet-form--content-hint {
  text-align: center;
  margin: 0 auto 0 auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.faucet-form--content-hint svg {
  vertical-align: text-top;
}

.faucet-form--content-hint .text-gradient {
  position: relative;
  margin-left: 4px;
  background: linear-gradient(90deg, #E6A394 -0.82%, #CD697B 122.95%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faucet-form--content-hint .text-gradient::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: linear-gradient(90deg, #E6A394 -0.82%, #CD697B 122.95%);
}

.faucet-form--error {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #FF9EB6;
}

.faucet-page .explorer-footer-container {
  padding-top: 114px;
}


@media screen and (max-width: 768px) {
  .faucet-page .transaction-popover-mobile {
    display: none;
  }

  .faucet-form {
    width: 100%;
    padding-right: 20px;
    gap: 16px;
    padding-bottom: 105px;
  }

  .faucet-form--content {
    gap: 16px;
  }

  .faucet-form--badge {
    padding: 8px 16px;
  }

  .faucet-form--heading {
    max-width: 317px;
    margin-bottom: 34px;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
  }

  .faucet-form--submit-btn {
    margin-top: 8px;
  }
}
