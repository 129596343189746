.exlorerContainer {
  gap: 10px;
  background-repeat: no-repeat;
  z-index: 1;
  min-height: 99.5vh;
}

.exlorerContainer .infinite-scroll-component__outerdiv,
.exlorerContainer .infinite-scroll-component {
  width: 100% !important;
}

.explorer-header-container {
  width: calc(100% + 42px * 2);
  margin-left: -84px;
  margin-right: -84px;
  margin-top: -0.5rem;
}

.explorerPageBg {
  max-width: 100vw;
  padding-inline: 42px;
}

.explorerTableContainer {
  width: 100%;
  padding-inline: 2.8rem;
}

.emptyTableImage {
  margin-top: 110px;
  width: 104px;
  height: 111px;
  margin-bottom: 47px;
}

.emptyTableText {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.connectWalletPlaceholder,
.emptyTablePlaceholder {
  width: 399px;
  height: 345px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connectWalletPlaceholder {
  position: relative;
  width: 360px;
  height: unset;
}

.connectWalletPlaceholder .emptyTableImage {
  width: 106px;
  height: 107px;
}

.connectWalletPlaceholder .emptyTableText {
  min-width: 246px;
  text-align: center;
}

.connectWalletPlaceholderButton {
  margin-top: 24px;
  max-width: 171px;
}

.searchContainer {
  height: 50px;
  margin-bottom: 32px;
  animation: opacityIn 1s ease;
}

.inputExplorer {
  height: 50px;
  display: flex;
  align-items: center;
}

.inputExplorer::placeholder {
  color: rgba(255, 255, 255, .6) !important;
  opacity: 1;
  font-weight: 500;
}

.explorerSearchBtn {
  width: 130px;
  height: 50px;
  padding: 16px 20px 16px 20px;
  margin-right: 8px;

  border-width: 0px;
  color: white;
}

.explorerSearchBtn.history {
  display: flex;
  align-items: center;
  width: 131px;
  gap: 8px;
}

.explorerSearchBtn.history img {
  min-width: 16px;
}

.leftExplorerSearchBtn {
  padding: 14px 20px 14px 20px;
  border-radius: 15px 0px 0px 15px;
  margin-inline: 8px;
}

.rightExplorerSearchBtn {
  width: 130px;
  height: 50px;
  padding: 16px 20px 16px 20px;
  border-radius: 0px 15px 15px 0px;
}

.gradientBg {
  background-image: linear-gradient(94.33deg, #E6A692 7.8%, #8F3465 103.43%);
}

.btnSearchBg {
  background-color: #ffffff20;
}

.explorerTable {
  background-color: #ffffff14;
  backdrop-filter: blur(60px);
  border-radius: 20px;
  overflow: auto;
  animation: opacityIn 1s ease;
}

.table-scroll-div {
  overflow-y: auto;
  height: 440px;
}

.table-scroll-div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 25px;
  background-color: #cecaff20;
}

.table-scroll-div::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #807fb9;
  border: 2px solid transparent;
  background-clip: content-box;
}

.tableExplorer {
  min-width: 1200px;
  margin: 20px 0 40px 40px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  width: 100%;
  height: 172px;
  table-layout: fixed;
}

.width10 {
  width: 10%;
}

.tableContainer {
  height: 572px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 20px 20px 40px 40px;
}

.tBodyClass {
  height: 172px;
  overflow-y: scroll;
  width: 100%;
  padding-top: 13px !important;
}

.table-scroll-div td {
  padding: 14px 7px !important;
}

.verticalAlignCenter {
  vertical-align: middle;
}

.copyIcon {
  width: 12px;
  height: 12px;
}

.textSecondaryTable {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #a39dc7;
}

.headlineText {
  font-size: 14px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
}

.tableExplorer {
  margin-right: 8px;
}

.tableExplorer tbody {
  display: block;
  height: 440px;
  overflow: auto;
}

.tableExplorer thead,
.tableExplorer tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableExplorer thead {
  width: calc(100% - 14px)
}

.cell-text-center {
  text-align: center;
}

.textTable {}

.explorer-footer-container {
  margin-top: auto;
  padding-top: 45px;
  width: 100%;
}

.statusTd {
  width: 12%;
}

.source-col {
  padding-left: 42px;
}

@media (max-width: 1200px) {

  .source-col {
    padding-left: 20px;
  }
}

.tableFont {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}

.textLinkTable {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;

  text-decoration: none;
  background: linear-gradient(94.33deg, #EFB09C 7.8%, #B75556 103.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.marginXAuto {
  margin-inline: auto;
}

.statusbtnContainer {
  width: 120px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-inline: auto;
}

.greenStatus {
  border: 0.5px solid #bdf8ca;
  background-color: #ceffe233;
  color: #bdf8ca;
}

.orangeStatus {
  border: 0.5px solid #ffb47e;
  /* background-color: #ffb47e33; */
  color: #ffb47e;
}

.redStatus {
  border: 0.5px solid #e58490;
  /* background-color: #e5849033; */
  color: #e58490;
}

.greenColor {
  color: #bdf8ca;
}

.orangeColor {
  color: #ffb47e;
}

.redColor {
  color: #e58490;
}

.statusText {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
}

.statusTextSecondary {
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #A39DC7;
}

.explorerModal {
  width: 492px;
  min-height: 618px;
}

.infoBlockcontainer {
  width: 406px;
  height: 100px;
  border-radius: 12px;
  margin-top: 8px;
  background: linear-gradient(180deg, rgba(223, 218, 255, 0.1) 0%, rgba(138, 121, 244, 0.1) 100%);
}

.search-bar-buttons-container {
  display: flex;
}

.linkBtn {
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: white;
}

.explorerFormBg {
  background: linear-gradient(177.54deg, #C7564F -19.41%, #0D0821 29.98%);
}

.explorerFormBgImageContainer {
  position: absolute;
  inset: 0;
  border-radius: inherit;
  overflow: hidden;
}

.explorer-form {
  border: 1px solid rgba(128, 128, 128, 0.2);
}

.time-section-bg {
  min-width: 130px;
  background-color: #ffffff20;
  margin-bottom: 20px;
}

.clockIcon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.explorerTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  align-self: flex-start;
  margin-top: 56px;
  margin-bottom: 32px;
  animation: opacityIn 1s ease;
}

@keyframes opacityIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.tableWalletSell:hover {
  color: #ffffff70;
  transition: all 0.5s ease;
}

.tableWalletSell {
  cursor: pointer;
}

.explorerModalTitle {
  margin-top: 40px;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  margin-bottom: 16px;
}

.textSecondaryExplorerModal {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #9794af;
}

.shareImg {
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.leftAmountBlockBtnDiv {
  background-color: #ffffff20;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 4rem;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-width: 0px;
}

.amountBlockBtnDivContainer {
  width: 49%;
}

.rightAmountBlockDiv {
  background-color: #ffffff20;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 4rem;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  transition: all 300 ease;
  overflow: hidden;
  border-width: 0px;
}

.closeIcon {
  width: 20px;
  height: 20px;
}

.closeBtn {
  padding: 5px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.networkInfoContainerBg {
  background-color: #ffffff20;
  height: 60px;
  border-radius: 15px;
  padding: 16px;
  padding-right: 12px;
  margin-bottom: 16px;
}

.infoBlock {
  background-color: #ffffff20;
  border-radius: 15px;
  overflow: hidden;
}

.height35 {
  height: 30px;
}

.opacityIn {
  animation: opacityIn 0.7s ease;
}

@keyframes opacityIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.table-header-status {
  padding-right: 210px !important;
}

.table-header-source {
  padding-right: 200px !important;
}

.table-header-destination {
  padding-left: 220px !important;
}

.table-header-from {
  padding-left: 260px !important;
}

.table-header-to {
  padding-left: 130px !important;
}

.table-header-date {}

.table-header-action {
  padding-right: 80px !important;
}

@media only screen and (max-width: 3100px) {
  .table-header-status {
    padding-right: 160px !important;
  }

  .table-header-source {
    padding-right: 130px !important;
  }

  .table-header-from {
    padding-left: 210px !important;
  }

  .table-header-date {
    padding-left: 40px !important;
  }

  .table-header-action {
    padding-right: 60px !important;
  }
}

@media only screen and (max-width: 2500px) {
  .table-header-status {
    padding-right: 100px !important;
  }

  .table-header-source {
    padding-right: 90px !important;
  }

  .table-header-destination {
    padding-left: 200px !important;
  }

  .table-header-action {
    padding-right: 40px !important;
  }
}

@media only screen and (max-width: 2100px) {
  .table-header-status {
    padding-right: 70px !important;
  }

  .table-header-source {
    padding-right: 70px !important;
  }

  .table-header-destination {
    padding-left: 180px !important;
  }

  .table-header-from {
    padding-left: 180px !important;
  }

  .table-header-action {
    padding-right: 25px !important;
  }
}

@media only screen and (max-width: 1900px) {
  .table-header-status {
    padding-right: 40px !important;
  }

  .table-header-source {
    padding-right: 40px !important;
  }

  .table-header-destination {
    padding-left: 160px !important;
  }
}

@media only screen and (max-width: 1700px) {
  .table-header-destination {
    padding-left: 140px !important;
  }

  .table-header-from {
    padding-left: 160px !important;
  }
}

@media only screen and (max-width: 1600px) {
  .table-header-status {
    padding-right: 0px !important;
  }

  .table-header-source {
    padding-right: 0px !important;
  }

  .table-header-from {
    padding-left: 145px !important;
  }

  .table-header-date {
    padding-left: 70px !important;
  }

  .table-header-action {
    padding-right: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .explorerPageBg {
    padding-right: 0 !important;
    padding-left: 20px;
    padding-bottom: 0 !important;
    overflow-x: hidden;
    background-image: none;
  }

  .explorer-header-container {
    width: calc(100% + 20px* 2);
    margin-left: -20px;
    margin-right: -20px;
    padding-right: 20px;
  }

  .explorerTitle {
    display: none !important;
  }

  .searchContainer {
    padding-right: 20px;
    margin-top: 43px;
    margin-bottom: 40px;
    flex-direction: column !important;
    height: auto;
  }

  .exlorerContainer {
    gap: 0;
  }

  .search-bar-buttons-container {
    width: 100%;
    margin-top: 8px;
  }

  .leftExplorerSearchBtn {
    margin-left: 0px !important;
  }

  .tableExplorer {
    margin: 0 !important;
  }

  .exlorerContainer .infinite-scroll-component__outerdiv {
    width: calc(100% + 40px) !important;
  }

  .explorerTable {
    background-color: transparent;
    backdrop-filter: unset;
    padding-left: 20px;
    margin-bottom: 48px;
  }

  .explorer-footer-container {
    padding-top: 0;
    margin-bottom: 23px;
    padding-left: 4px;
    padding-right: 26px;
  }

  .explorerFormBg {
    background: linear-gradient(181.23deg, #C7564F 1.05%, #0D0821 61.36%);
  }

  .explorerModal {
    width: 100%;
    padding-inline: 20px !important;
  }

  .explorer-modal-p-x {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .infoBlockcontainer {
    width: 100%;
  }

  .time-section-bg {
    margin-bottom: 0;
  }

  .emptyTableText {
    font-size: 18px;
    line-height: 24px;
    width: 176px;
    text-align: center;
  }

  .emptyTableImage {
    margin-top: 120px;
    margin-bottom: 23px;
    width: 90px;
    height: 96px;
  }

  .connectWalletPlaceholder .emptyTableImage {
    width: 91px;
    height: 92px;
  }

  .explorer-form-pb {
    padding-bottom: 51px !important;
  }

  .closeBtn {
    z-index: 11;
  }

  .connectWalletPlaceholder,
  .emptyTablePlaceholder {
    position: relative;
    margin-left: -10px;
    margin-bottom: 178px;
  }

  .explorerPageBgEmptyOrders {
    background-image: radial-gradient(circle at 50% 482px,
        rgba(119, 78, 235, 0.8) -26%,
        rgba(0, 0, 0, 0) 50%);
  }

  .emptyTablePlaceholder::before,
  .connectWalletPlaceholder::before {
    position: absolute;
    content: '';
    top: 56px;
    width: 331.88px;
    height: 287.16px;
    background: #BD557A;
    opacity: .6;
    z-index: -1;
    transform: rotateZ(6.95deg);
    filter: blur(126px);
  }
}

@media only screen and (max-width: 600px) {
  .explorerSearchBtn.history {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    padding: 0;
    margin-right: 0;
  }

  .explorerSearchBtn.history span {
    display: none;
  }
}
