.notifications-menu--other-item {
  position: relative;
  display: block;
  padding: 22px 19px 22px 19px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: #FFFFFFA3;
  text-decoration: none !important;
}

.notifications-menu--other-item::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  border: 0.5px solid #fff;

  mask: linear-gradient(111.85deg, rgba(255, 255, 255, .5) -51.74%, rgba(255, 255, 255, 0) 112.65%);
  pointer-events: none;
}

.notifications-menu--other-item strong {
  color: #fff;
}
