.faucet-bg {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  z-index: -1;
}

.faucet-bg--img {
  position: absolute;
  filter: drop-shadow(0px 0px 14.145px #F7BCA2BA);
}

.faucet-bg--img:nth-child(1) {
  width: 39.73px;
  height: 43.34px;
  transform: translate(-304px, 136px);
}

.faucet-bg--img:nth-child(2) {
  width: 50.56px;
  height: 55.38px;
  transform: translate(-344px, 45px);
}

.faucet-bg--img:nth-child(3) {
  width: 31.3px;
  height: 33.71px;
  transform: translate(-230px, 8px);
}

.faucet-bg--img:nth-child(4) {
  width: 50.56px;
  height: 55.38px;
  transform: translate(-147px, -56px);
}

.faucet-bg--img:nth-child(5) {
  width: 50.56px;
  height: 55.38px;
  transform: translate(147px, -25px);
}

.faucet-bg--img:nth-child(6) {
  width: 42.14px;
  height: 45.75px;
  transform: translate(306px, -25px);
}

.faucet-bg--img:nth-child(7) {
  width: 50.56px;
  height: 55.38px;
  transform: translate(336px, 93px);
}

.faucet-bg--img:nth-child(8) {
  width: 34.44px;
  height: 37.09px;
  transform: translate(386px, 154px);
}

@media screen and (max-width: 768px) {
  .faucet-bg--img:nth-child(1) {
    width: 32.36px;
    height: 35.3px;
    transform: translate(-172px, 99px);
  }

  .faucet-bg--img:nth-child(2) {
    width: 41.18px;
    height: 45.1px;
    transform: translate(-193px, 25px);
  }

  .faucet-bg--img:nth-child(3) {
    width: 25.49px;
    height: 27.46px;
    transform: translate(-124px, 6px);
  }

  .faucet-bg--img:nth-child(4) {
    width: 41.18px;
    height: 45.1px;
    transform: translate(164px, 66px);
  }

  .faucet-bg--img:nth-child(5) {
    width: 34px;
    height: 37.24px;
    transform: translate(117px, -16px);
  }

  .faucet-bg--img:nth-child(6) {
    width: 30px;
    height: 33px;
    transform: translate(170px, 149px);
    opacity: .5;
  }

  .faucet-bg--img:nth-child(7) {
    width: 50.56px;
    height: 55.38px;
    transform: translate(336px, 93px);
    display: none;
  }

  .faucet-bg--img:nth-child(8) {
    width: 34.44px;
    height: 37.09px;
    transform: translate(386px, 154px);
    display: none;
  }
}
