.transaction-notification {
  position: fixed;
  top: 99px;
  right: 33px;
  bottom: 33px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10;
  color: #fff;
  pointer-events: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.transaction-notification-item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  border: 0.5px solid rgba(255, 255, 255, 0.15);
  pointer-events: all;
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
}

.hidden .transaction-notification-item {
  pointer-events: none;
}

.transaction-notification-item-popover {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
  color: #fff;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
}

.transaction-notification-item-popover:last-child {
  border-bottom: none;
}

.transaction-notification-item.close {
  opacity: 0;
  transform: translateX(100%);
}

.transaction-notification-item.open {
  opacity: 1;
  transform: translateX(0);
  animation: notification-open 0.3s ease-in-out;
}

.transaction-notification-item--headline {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.transaction-notification-item--headline .token-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  aspect-ratio: 1/1;
  border-radius: 100px;
  background: #fff;
}

.transaction-notification-item--headline .token-icon img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.transaction-notification-item--amount {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
}

.transaction-notification-item--amount img {
  max-width: 14px;
  height: 14px;
}

.transaction-notification-item--status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transaction-notification-badge {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  border-radius: 7px;
  border: 0.5px solid rgba(142, 131, 208, .195);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.transaction-notification-badge.in-progress {
  background: rgba(209, 149, 106, 0.18);
  color: #F8CBAB;
}

.transaction-notification-badge.in-progress svg {
  animation: spin 2s linear infinite;
}

.transaction-notification-badge.completed {
  border-color: rgba(255, 255, 255, .195);
  background: rgba(67, 255, 176, 0.18);
  color: #7CFFC8;
}

.transaction-notification-item--action {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  background: linear-gradient(94.33deg, #EFB09C 7.8%, #B75556 103.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.transaction-notification-item--action img {
  min-width: 20px;
  width: 20px;
  height: 20px;
  transform: rotateZ(180deg);
  fill: linear-gradient(89.75deg, #8F7CFF 17.51%, #E5C4FF 101.93%);
}

.transaction-notification-item--close {
  position: absolute;
  top: 10px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
}

.transaction-notification-item--close.close-timer {
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #8C8C8C;
}

.close-timer .close-timer-progress {
  position: absolute;
  min-width: 20px;
  height: 20px;

  animation: 6.3s linear 0s 1 normal none running timer-circle;
}

@keyframes notification-open {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes timer-circle {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 100;
  }
}

@media screen and (max-width: 992px) {
  .transaction-notification {
    display: none;
  }

  .transaction-notification-item--action {
    font-size: 12px;
  }

  .transaction-notification-item--headline {
    font-size: 13px;
  }
}
