.faucet-form--address-group--item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 0 30px;
  gap: 10px;
  height: 60px;
  background: rgba(255, 255, 255, .1);
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #fff;
}

.faucet-form--address-group--item:first-child {
  justify-content: flex-start;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.faucet-form--address-group--item:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.faucet-form--address-group--item::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background: linear-gradient(94.33deg, #EFB09C 7.8%, #B75556 103.43%);
  transition: opacity .3s ease-in-out;
  opacity: 0;
}

.faucet-form--address-group--item.active::before {
  opacity: 1;
}

.faucet-form--address-group--item img {
  min-width: 20px;
  width: 20px;
  height: 20px;
}

.faucet-form--address {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(255, 255, 255, .6);
}

.faucet-form--address-input-container {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 12px 0 0;
  width: 100%;
  margin-top: 8px;
  background: rgba(255, 255, 255, .1);
  border-radius: 12px;
}

.faucet-form--address-input {
  flex-grow: 1;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: rgba(255, 255, 255, .7);
}

.faucet-form--address-input::placeholder {
  color: rgba(255, 255, 255, .4);
}

.faucet-form--address-input-feedback {
  min-width: 20px;
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 768px) {
  .faucet-form--address-group--item {
    width: 100%;
    height: 50px;
    flex-grow: 1;
    font-size: 12px;
  }

  .faucet-form--address-group--item:first-child {
    padding-left: 14.75px;
    padding-right: 14.75px;
  }

  .faucet-form--address-group--item:last-child {
    padding: 0;
  }

  .faucet-form--address-input-container {
    height: 50px;
  }
}
