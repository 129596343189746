.faucet-claim-dialog--overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, .7);
  z-index: 10;
}

.faucet-claim-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
  max-width: 491px;
  padding: 40px 60px 60px 60px;
  transform: translate(-50%, -50%);
  background: #000;
  border: 1px solid #FFFFFF40;
  border-radius: 24px;
  z-index: 11;
  color: #fff;
}

.faucet-claim-dialog--bg {
  position: absolute;
  inset: 0;
  border-radius: inherit;
  z-index: -1;
  overflow: hidden;
}

.faucet-claim-dialog--bg img {
  width: 100%;
}

.faucet-claim-dialog--heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}

.faucet-claim-dialog--heading-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 100px;
}

.faucet-claim-dialog--heading-icon img {
  min-width: 18px;
  width: 18px;
  height: 18px;
}

.faucet-claim-dialog--text {
  max-width: 272px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: rgba(255, 255, 255, .7);
}

.faucet-claim-dialog--text strong {
  font-weight: 500;
  color: #fff;
}

.faucet-claim-dialog--state {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49px;
  height: 49px;
  margin: 0 auto;
  background: rgba(255, 255, 255, .1);
  border-radius: 100px;
}

.faucet-claim-dialog--action {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
  color: #FFF;
  outline: none;
}
