.faucet--select-network {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 12px;
  padding: 20px 12px 20px 17px;
  border: none;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background: rgba(255, 255, 255, .1);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #fff;
}

.faucet--select-network-trigger-icon {
  min-width: 14px;
  width: 14px;
  height: 14px;
  margin-left: auto;
}

[data-state='open'] .faucet--select-network-trigger-icon {
  transform: rotate(180deg);
}

.faucet--select-network-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 100px;
}

.faucet--select-network-icon img {
  min-width: 16px;
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.faucet--select-network-menu {
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: var(--radix-popover-trigger-width);
  min-height: 100px;
  max-height: 313px;
  padding: 20px;
  border-radius: 16px;
  background: linear-gradient(180deg, rgba(79, 45, 51, 0.8) 0%, rgba(55, 17, 44, 0.8) 93.5%);
  backdrop-filter: blur(20px);
  color: #fff;
}

.faucet--select-network-menu .simplebar-scrollbar::before {
  background-color: rgba(255, 255, 255, .6) !important;
  opacity: 1 !important;
  width: 4px !important;
  top: 2px !important;
  bottom: 2px !important;
  left: 50%;
  transform: translateX(-50%);
}

.faucet--select-network-menu .simplebar-vertical {
  display: flex;
  justify-content: center;
  width: 8px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 25px !important;
}

.faucet--select-network-menu-item {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin: -4px 0;
  padding: 4px 0;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #fff;
  outline: none;
}

.faucet--select-network-menu-item.disabled-network {
  color: rgba(163, 157, 199, 1) !important;
}

.faucet--select-network-menu-item .disabled-network-description {
  margin-left: auto;
  padding-right: 4px;
  color: rgba(163, 157, 199, 1);
  font-size: 10px;
}

.faucet-form--network-amount {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 107px;
  min-height: 60px;
  padding: 20px 16px 20px 20px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background: rgba(255, 255, 255, .1);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.faucet-form--network-amount-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 100px;
}

.faucet-form--network-amount-icon img {
  min-width: 16px;
  width: 16px;
  height: 16px;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .faucet--select-network {
    padding: 15px 16px 15px 12px;
  }

  .faucet-form--network-amount {
    min-height: 50px;
    padding: 15px 16px 15px 12px;
  }

}
