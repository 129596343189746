.network-switch {
  position: relative;
  display: flex;
  padding: 4px;
  background: rgba(255, 255, 255, .05);
  border-radius: 50px;
}

.network-switch::before {
  position: absolute;
  content: '';
  top: 4px;
  bottom: 4px;
  width: 78px;
  border-radius: 50px;
}

.network-switch.mainnet::before {
  background: linear-gradient(93deg, #BBA0E6 1.12%, #7556F1 98.02%);
}

.network-switch.testnet::before {
  width: 128px;
  right: 4px;
  background: linear-gradient(90deg, #FFB077 0%, #B75959 100%);
}

.network-switch--item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 14px;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(255, 255, 255, .3);
  transition: color .3s ease-in-out;
  text-decoration: none !important;
  z-index: 1;
}

.network-switch--item:hover {
  color: #fff;
}

.network-switch.mainnet .network-switch--item:nth-child(1),
.network-switch.testnet .network-switch--item:nth-child(2) {
  color: #fff;
}
