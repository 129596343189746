.statusForm {
  padding: 77px 34px;
  width: 474px;
}

.rejectCircle {
  width: 54px;
  height: 54px;
  border-radius: 30px;
  border: 2px solid #FF9EB6;
  box-shadow: 0px 0px 10px 0px #FF9EB6;

}

.statusTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-top: 24px;
  margin-bottom: 8px;
}

.circle-bg-success-page {
  background-color: #2B161A !important;
}

.statusFormPadding {
  margin-bottom: 98px;
}

.statusSubtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #d1cbfa;
  margin-bottom: 32px;
  max-width: 315px;
  text-align: center;
}

.greenCircle {
  width: 54px;
  height: 54px;
  border: 2px solid #94c79c;
  box-shadow: 0px 0px 10px 0px #c3ffc1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.greenCheck {
  width: 16px;
  height: 11px;
}

.redClose {
  width: 22.5px;
  height: 22.5px;
}



.width258 {
  margin-top: 42px;
  margin-bottom: 24px;
  width: 258px;
  height: 50px;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: radial-gradient(circle,
      #C2595F 34%,
      #EA886E 86%,
      #D9A598 100%);
  box-shadow: 0px -1px 6px 0px #ffffffcf inset, 0px 2px 4px 0px #ffeec159 inset;
}

.width258:hover {
  color: #ffffff !important;
  box-shadow: 0px 0px 30px 4px #ffffff50, 0px -1px 6px 0px #ffffffcf inset, 0px 2px 4px 0px #ffeec159 inset !important;
}

.view-in-explorer-btn {
  position: relative;
  margin-bottom: 32px;
}

.view-in-explorer-btn-text {
  position: relative;
  background: linear-gradient(93.76deg, #AFA2FF 4.37%, #E5C4FF 100.44%);
  text-decoration: underline;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.view-in-explorer-btn-text::after {
  content: '';
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(93.76deg, #AFA2FF 4.37%, #E5C4FF 100.44%);
}

.transfer-btn-group {
  margin-bottom: 16px;
}

.add-token-button {
  width: 100%;
  height: 50px;
  padding: 16px 24px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, .1);
  box-shadow: none !important;

  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  transition: all 0.5s ease;
}

.add-token-button:hover {
  color: #fff !important;
  box-shadow: white 0px 0px 1px !important;
}

.add-token-button:disabled {
  color: #fff !important;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, .1);
  opacity: .5;
  transition: all 0.5s ease;
}

.add-token-button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 100px;
}

.add-token-button-icon img {
  min-width: 16px;
  height: 16px;
  object-fit: contain;
}

.add-token-contract {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, .6);
}

.globe {
  width: 18px;
  height: 18px;
}

@media only screen and (max-width: 480px) {
  .statusForm {
    width: 100%;
    padding: 0px !important;
  }

  .statusForm.bg-dark {
    background: transparent !important;
  }

  .status-success-form-margin {
    margin-top: 80px;
    margin-bottom: 182px;
  }

  .status-success-width {
    width: 100%;
  }

  .greenCircle {
    width: 42px;
    height: 42px;
  }

  .statusSubtitle {
    margin-bottom: 32px;
  }

  .create-another-transfer-button {
    margin-top: 32px !important;
    margin-bottom: 24px !important;
  }
}
