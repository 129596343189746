.my-history-popover {
  display: flex;
  flex-direction: column;
  width: 390px;
  min-height: 192px;
  max-height: var(--radix-popover-content-available-height);
  background: rgba(255, 255, 255, 0.1);
  padding: 24px;
  border-radius: 24px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  z-index: 1;
  backdrop-filter: blur(25px);
  color: #fff;
  outline: none;
}

.my-history-popover--close {
  position: absolute;
  top: 22px;
  right: 18px;
  background: transparent;
  border: none;
  padding: 0;
}

.my-history-popover--close img {
  width: 16px;
  height: 16px;
}

.my-history-popover--tabs {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}

.my-history-popover--tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 116px;
  padding: 6px 16px;
  border-radius: 40px;
  border: none;
  background: rgba(255, 255, 255, .1);
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #fff;
  transition: background-image 0.3s ease-in-out;
}

.my-history-popover--tab.active {
  background: linear-gradient(94.33deg, #EFB09C 7.8%, #B75556 103.43%);
}

.my-history-popover .simplebar-scrollbar::before {
  background-color: #807fb9 !important;
  opacity: 1 !important;
  width: 4px !important;
  top: 4px !important;
  bottom: 4px !important;
  left: 50%;
  transform: translateX(-50%);
}

.my-history-popover .simplebar-vertical {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 25px !important;
  display: flex;
  justify-content: center;
}

.my-history-popover--content.recent {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
}

.my-history-popover--action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 24px auto 0 auto;
  background: linear-gradient(94.33deg, #EFB09C 7.8%, #B75556 103.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;

  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.my-history-popover--action img {
  width: 19px;
  height: 19px;
}

.my-history-popover--empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding-top: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.my-history-popover--empty img {
  width: 46px;
  height: 46px;
}

.transaction-popover-mobile {
  display: none;
}

.my-history-popover--headline {
  display: none;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

@media screen and (max-width: 992px) {
  .transaction-popover-mobile {
    display: block;
    position: fixed;
    bottom: 61px;
    right: 20px;
    z-index: 10;
  }

  .header-mobile-history {
    min-width: 42px;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border: none;
    border-radius: 8px !important;
    background: #CF676C;
    box-shadow: 0px -1px 6px 0px #FFFFFFCF inset, 0px 2px 4px 0px #FFEEC159 inset;
  }

  .header-mobile-history img {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }

  .header-mobile-history--count {
    border-radius: 100px;
    background: #fff;
    font-size: 8px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #B16060;
    transform: translate(18px, -18px);
  }

  .my-history-popover {
    background: linear-gradient(126.05deg, #DA9380 4.65%, #5D3148 48.39%, #230C18 78.17%) no-repeat,
      linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)) no-repeat;
    backdrop-filter: none;
  }

  .my-history-popover--close {
    display: none;
  }

  .my-history-popover--headline {
    display: block;
  }

  .my-history-popover--tabs {
    display: none;
  }

  .my-history-popover--action {
    font-size: 12px;
  }
}

@media screen and (max-width: 486px) {
  .my-history-popover {
    width: calc(100vw - 40px);
  }
}
