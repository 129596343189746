.notifications-menu--new-destination {
  position: relative;
  padding: 11px 84px 9px 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  border-radius: 12px;
  cursor: pointer;
}

.notifications-menu--new-destination::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  border: 0.5px solid #fff;

  mask: linear-gradient(111.85deg, rgba(255, 255, 255, 0.5) -51.74%, rgba(255, 255, 255, 0) 112.65%);
}

.notifications-menu--new-destination--heading {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}

.notifications-menu--new-destination--date {
  font-size: 11px;
  font-weight: 500;
  line-height: 16.5px;
  color: rgba(255, 255, 255, 0.6);
}

@media screen and (max-width: 768px) {
  .notifications-menu--new-destination--heading br {
    display: none;
  }
}
