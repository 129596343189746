.arrowDownIcon {
  width: 8px;
  height: 12px;
  border-radius: 15px;
}

.secondarytext {
  color: rgba(255, 255, 255, .6);
}

.circle {
  width: 34px;
  height: 34px;
  border-radius: 20px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: -14px;
  background-color: #79435a;
  position: relative;
}

.transferInfoSubtitle {
  font-size: 14px;
  font-weight: '700';
  margin-bottom: 8px;
}

.textAlignEnd {
  text-align: end;
}

.backBtn {
  text-decoration: none;
  color: white;
  font-weight: 700;
}

.btnInfoGradient {
  background-image: linear-gradient(
    90deg,
    #fff0fe99,
    #8f7cff99,
    #8f7cff99,
    #fff0fe99
  );
  border-radius: 2rem;
  color: white;
  height: 3rem;
  width: 3rem;
}
