.header-standalone-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 168px;
  background: #FFFFFF1A;
  border: 0.5px solid #FFFFFF80;
  border-radius: 60px;
  margin-right: 8px;

  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;

  animation: opacityIn 1s ease;
}

.header-mainnet-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;

  animation: opacityIn 1s ease;
}

.header-mainnet-link .text-gradient {
  background: linear-gradient(90deg, #E5C4FF 0%, #BAAFFF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header {
  display: flex;
  align-items: center;
  padding: 23px 29px 0 53px;
  min-height: 73px;
}

.header-nav {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 36px;
}

.app-header-nav {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 20px;
}

.header-nav-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none !important;
  color: #fff !important;
}

.header-nav-item--divider {
  height: 100%;
  min-height: 28px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.header-nav-item.headerBtn {
  margin-left: -16px;
}

.header-nav-item--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 74px;
  height: 42px;
  margin-right: 18px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-decoration: none !important;
}

.header-network-menu--trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  margin-top: 3.5px;
  padding: 0 12px;
  width: 89px;
  height: 28px;
  background: #FFFFFF1A;
  border: 0.5px solid #FFFFFF80;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #fff;
  outline: none;
}

.header-network-menu--trigger img,
.header-network-menu--item img {
  min-width: 14px;
  width: 14px;
  height: 14px;
}

.header-network-menu--trigger[data-state="open"] img {
  transform: rotate(180deg);
}

.header-network-menu {
  position: relative;
  z-index: 100;
  width: var(--radix-popover-trigger-width);
  outline: none;
}

.header-network-menu--item {
  display: flex;
  align-items: center;
  height: 28px;
  justify-content: space-between;
  padding: 0 10px 0 12px;
  border-radius: 20px;
  border: 0.5px solid #FFFFFF80;
  background: #FFFFFF1A;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #fff !important;
  text-decoration: none !important;
}

.header-network-menu--item .testnet-label,
.header-network-menu--trigger .testnet-label {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.header-network-menu--item .testnet-label::before,
.header-network-menu--trigger .testnet-label::before {
  content: '';
  position: relative;
  display: flex;
  width: 5px;
  min-width: 5px;
  height: 5px;
  background: #FFB06B;
  border-radius: 100px;
}

@media screen and (max-width: 1200px) {
  .header-nav {
    gap: 20px;
  }

  .app-header-nav {
    gap: 10px;
  }
}

@media screen and (max-width: 992px) {
  .header {
    padding: 23px 15px 0 5px;
  }

  .header.app-header {
    padding: 23px 15px 0 25px;
  }

  .header-nav,
  .app-header-nav {
    display: none;
  }
}
