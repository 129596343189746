.notifications-menu--journey-item {
  display: flex;
  align-items: center;
  padding: 16px 19px 19px 16px;
  border: 0.5px solid #FFFFFF33;
  border-radius: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: #FFFFFFCC;
  text-decoration: none !important;
}

.notifications-menu--journey-item strong,
.notifications-menu--journey-item span {
  color: #fff;
}

.notifications-menu--journey-item--thumbnail {
  position: relative;
  min-width: 60px;
  width: 60px;
  height: 60px;
  margin: auto 0 auto auto;
  border-radius: 6px;
  overflow: hidden;
}

.notifications-menu--journey-item--thumbnail::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  border: 0.23px solid #fff;
  mask: linear-gradient(169.93deg, #FFFFFF 7.54%, rgba(255, 255, 255, 0) 106.65%);
}

.notifications-menu--journey-item--thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
