.footerBtn {
  border-radius: 10px;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
}

.infoBlock {
  background-color: #ffffff10;
  border-radius: 15px;
  overflow: hidden;
  transition: height 300ms ease-out;
}

.walletDisconnectModalTopText {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #a39dc7;
}

.walletDisconnectModal__account-wrapper {
  justify-content: space-between;
}

@media (max-width: 610px) {
  .footer-faq {
    display: none !important;
  }
}

@media (max-width: 480px) {

  .walletDisconnectModalTopText,
  .walletDisconnectModalBottomText span {
    /* padding: 0 !important;
    margin: 0 !important; */

    /* text-wrap: nowrap; */
    white-space: nowrap;
  }

  .walletDisconnectModalTopText,
  .walletDisconnectModalBottomText {
    padding: 0 !important;
    margin: 0 !important;

    white-space: nowrap;
  }
}

.walletDisconnectModalBottomText {
  font-weight: 700;
  color: white;
}

.walletDisconnectModalDivider {
  background-color: #b5b2c938;
  height: 1px;
  margin: 14px 0;
}

.walletDisconnectModalDangerText {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ff9eb6;
}

.powerIconSize {
  width: 14px;
  height: 14px;
  margin-right: 13px;
}

.power-icon-display {
  display: block;
}

.power-icon-white-display {
  display: none;
}

.walletDisconnectModalDangerDiv {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding-right: 20px;
  padding-left: 20px;
  padding-block: 5px;
  cursor: pointer;
}

.walletDisconnectModal {
  width: 200px;
  height: 130px;
  padding: 20px 0;
  border-radius: 12px;
  border: 0.5px solid #ffffff80;
  gap: 14px;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
  display: block;
  transition: all 0.5s ease;
  z-index: 100;
  backdrop-filter: blur(25px);
}

.walletConnectModal {
  width: 200px;
  min-width: 200px;
  height: 110px;
  min-height: 110px;
  padding: 20px 16px 20px 16px;
  border-radius: 12px;
  border: 0.5px solid #ffffff80;
  gap: 14px;
  position: absolute;
  top: 110%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s ease;
  z-index: 100;
}

.walletConnectModalText {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: start;
  color: white;
}

.walletDisconnectModalImg {
  width: 28px;
  height: 28px;
  /* margin-right:12px ; */
  border-radius: 40px;
  background-color: white;
}

.walletDisconnectModalHide {
  width: 100%;
  height: 130px;
  padding-top: 20px;
  padding-left: 16px;
  border-radius: 12px;
  border: 0.5px solid #ffffff80;
  gap: 14px;
  position: absolute;
  top: 30px;
  left: 0;
  opacity: 0;
  background-color: #ffffff20;
  transition: all 0.5s ease;
  display: none;
}

.dropContainerDiv {
  position: absolute;
  top: 110%;
  width: 100%;
  height: 0px;
}

.topReceiveBlock {
  min-height: 60px;
  padding: 12px 17px;
  align-items: center;
  margin-bottom: 8px;
}

.topReceiveBlock-token {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 100px;
}

.topReceiveBlock-token img {
  min-width: 18px;
  width: 18px;
  height: 18px;
}

.receiveBlock {
  padding: 16px 17px 16px 17px;
  margin-bottom: 24px;
}

.receiveUsdAmount {
  margin-right: 8px;
}

.receiveShortBlock {
  font-size: 12px;
  text-align: end;
}

.receiveShortBlock p {
  margin: 0;
}

.infoSeparator {
  height: 36px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 16px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -90px 0 0 -90px;
}

.loader .circle {
  position: absolute;
  border: 3px solid transparent;
  border-top-color: #C9726D;
  border-radius: 50%;
  background-color: transparent;
}

.loader .circle.one {
  height: 50px;
  width: 50px;
  left: 50px;
  top: 50px;
  transform: rotateZ(0deg);
  animation: rotate1 linear infinite;
  animation-duration: 0.85s;
}

.loader .circle.two {
  height: 75px;
  width: 75px;
  top: 38px;
  left: 38px;
  transform: rotateZ(140deg);
  animation: rotate2 linear infinite;
  animation-duration: 0.95s;
}

.loader .circle.three {
  height: 100px;
  width: 100px;
  top: 25px;
  left: 25px;
  transform: rotateZ(280deg);
  animation: rotate3 linear infinite;
  animation-duration: 1.05s;
}

@keyframes rotate1 {
  from {
    transform: rotateZ(360deg);
  }

  to {
    transform: rotateZ(0deg);
  }
}

@keyframes rotate2 {
  from {
    transform: rotateZ(500deg);
  }

  to {
    transform: rotateZ(140deg);
  }
}

@keyframes rotate3 {
  from {
    transform: rotateZ(640deg);
  }

  to {
    transform: rotateZ(280deg);
  }
}

.activeWalletsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 0px;
}

.activeWalletsGridItem0 {
  grid-area: 1 / 1 / 2 / 3;
}

.activeWalletsGridItem1 {
  grid-area: 2 / 1 / 3 / 2;
}

.activeWalletsGridItem2 {
  grid-area: 2 / 2 / 3 / 3;
}

.activeWalletsGridItem3 {
  grid-area: 3 / 1 / 4 / 2;
}

.activeWalletsGridItem4 {
  grid-area: 3 / 2 / 4 / 3;
}

.activeWalletsGrid .disabledSoon {
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.05);

  -webkit-user-select: none;
  user-select: none;
}

.activeWalletsGrid .disabledSoon span {
  color: rgba(255, 255, 255, 0.4);
}

.starknetWallets {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

@media only screen and (max-width: 480px) {
  .walletDisconnectModal {
    /* background-image: linear-gradient(#ffffff10, #ffffff10);
    backdrop-filter: blur(5px); */
    background: rgba(255, 255, 255, 0.1);
    border: 0.5px solid rgba(255, 255, 255, 0.5);
  }

  .walletDisconnectModalBottomText {
    font-size: 12px;
  }

  .power-icon-display {
    display: none;
  }

  .power-icon-white-display {
    display: block;
  }

  .activeWalletsGrid {
    display: flex;
    flex-direction: column;
  }

  .starknetWallets {
    flex-direction: column;
    gap: 0;
  }

  .disabledSoon {
    display: none !important;
  }
}

.absoluteCenter .modal-dialog {
  position: absolute;
  transform: translate(-50%, -50%) !important;
  left: 50%;
  top: calc(50% + 35px);
}

.toggleInfoBtn {
  border: none;
  background-color: transparent;
  outline: none;
}

svg .loader-ring {
  transform-origin: 55px 55px;
  animation:
    starting-fill 0.5s forwards,
    vary-loader-width 3s 0.5s linear infinite alternate,
    spin 1.6s 0.2s linear infinite;
}

.header--coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93px;
  height: 22px;
  border-radius: 16px;
  padding: 2px 10px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes starting-fill {
  to {
    stroke-dashoffset: 50;
  }
}

@keyframes vary-loader-width {
  0% {
    stroke-dashoffset: 50;
  }

  50% {
    stroke-dashoffset: 170;
  }

  100% {
    stroke-dashoffset: 260;
  }
}

.info-block-nft-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  padding: 0 8px;
  border-radius: 100px;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  color: #fff;
}

.info-block-nft-badge.late {
  background: linear-gradient(90deg, #BB3F55 0%, #E9AB88 100%);
}

@media screen and (max-width: 480px) {
  .info-block-nft-badge {
    font-size: 7px;
  }
}

.info-block-compression-row {
  margin-top: 16px;
  padding-top: 12px;
  border-top: 0.5px solid #6B659280;
}

.info-block-compression-label {
  min-width: 80px;
}

.competitors-group {
  width: 38px;
  margin-left: 4px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.competitors-group img {
  min-width: 18px;
  width: 18px;
  height: 18px;
  object-fit: contain;
  box-shadow: 1px 0px 4px 0px #00000040;
  border-radius: 100px;
}

.competitors-group img:nth-child(2) {
  transform: translateX(calc(8px));
}

.competitors-group img:nth-child(3) {
  transform: translateX(calc(16px));
}

.mobile-menu-header {
  height: 50px;
}
