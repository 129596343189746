.notifications-menu--trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  padding: 0;
  color: #fff;
}

.notifications-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 402px;
  max-height: 100%;
  padding: 16px 20px 20px 20px;
  background: rgba(255, 255, 255, .05);
  border-radius: 20px;
  outline: none;
  z-index: 100;
  backdrop-filter: blur(50px);
  box-shadow: -7px 16px 60px 0px #00000099;
  transform-origin: 50% 0%;
  color: #fff;
}

.notifications-menu::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  border: 1px solid #fff;
  mask: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.04) 100%);
  z-index: -1;
  pointer-events: none;
}

.notifications-menu--close {
  position: absolute;
  top: 24px;
  right: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  background: transparent;
  border: none;
  z-index: 10;
  padding: 0;
}

.notifications-menu--tabs {
  display: flex;
  gap: 6px;
  margin-bottom: 18px;
}

.notifications-menu--tabs-trigger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 40px;

  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: #fff;
}

.notifications-menu--tabs-trigger::before {
  content: '';
  position: absolute;
  inset: -1px;
  background: linear-gradient(99.25deg, #FFB08E 13.33%, #D96C6B 91.71%) no-repeat;
  border-radius: inherit;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.notifications-menu--tabs-trigger[data-state="active"]::before {
  opacity: 1;
}

.notifications-menu--tabs-trigger--counter {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  background: linear-gradient(99.25deg, #FFB08E 13.33%, #D96C6B 91.71%);
  border-radius: 100px;
  font-size: 11px;
  font-weight: 500;
  line-height: 16.5px;
}

.notifications-menu--tabs-trigger[data-state="active"] .notifications-menu--tabs-trigger--counter {
  background: rgba(255, 255, 255, 0.2);
}

.notifications-menu--tabs-content {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.notifications-menu--group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.notifications-menu--group-heading {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

@media screen and (max-width: 768px) {
  .notifications-menu {
    max-width: calc(100vw - 36px);
    background: #3A100763;
  }
}
